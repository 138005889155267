.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.form-signin {
	width: 100%;
	max-width: 330px;
	padding: 15px;
	margin: auto;
}

.form-signin .checkbox {
	font-weight: 400;
}

.form-signin .form-floating:focus-within {
	z-index: 2;
}

.form-signin input[type="email"] {
	margin-bottom: -1px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
	margin-bottom: 10px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.rounded-6 {
	border-radius: 3rem;
}

.carousel-item {
	height: 32rem;
}

.font-avo {
	font-family: "UTMAvo";
}

.font-avo--bold {
	font-family: "UTMAvo-Bold";
}
.capitalize {
	text-transform: capitalize;
}

.font-18 {
	font-size: 18px;
}

.text-orange {
	color: rgb(242, 108, 44);
}
.text-orange-hover:hover {
	color: rgb(189, 60, 0);
}
.text-blue {
	color: rgb(46, 49, 146);
}
.text-blue-hover:hover {
	color: rgb(13, 17, 138);
}
.text-sky{
	color: #2CA9E0;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
	color: rgb(242, 108, 44);
	border-bottom: 2px solid rgb(242, 108, 44);
}

.navbar-light .navbar-nav .nav-link.active:hover,
.navbar-light .navbar-nav .show:hover > .nav-link {
	color: rgb(189, 60, 0);
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav > .nav-link {
	color: rgb(46, 49, 146);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav:hover > .nav-link {
	color: rgb(13, 17, 138);
}

.product-inner-div {
	width: 80%;
	height: 300px;
	border-radius: 21px 21px 0 0;
}

.btn-empty {
	box-sizing: content-box;
	width: 1em;
	height: 1em;
	padding: 0.25em 0.25em;
	color: #000;
	background: transparent center/1em auto no-repeat;
	border: 0;
	border-radius: 0.25rem;
	opacity: 0.5;
}

.btn-empty:hover {
	color: #000;
	text-decoration: none;
	opacity: 0.75;
}

.number-group button {
	display: none;
}

.number-group input[type="number"]::-webkit-inner-spin-button,
.number-group input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.number-group input[type="number"] {
	-moz-appearance: textfield;
}
@media (min-width: 768px) {
	.number-group button {
		display: block;
	}
}

.btn_disabled {
	opacity: 0.6 !important;
	cursor: not-allowed !important;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

/* backgrounds */

.bg-kids1 {
	background-image: url("https://bikids.edu.vn/img/bg_kids1.png");
	background-size: 100%;
  }

.bg-orange {
	background: rgb(242, 108, 44);
	color: white;
}

/* Border */

.border-orange {
	border-color: rgb(242, 108, 44)!important;
}

/* Pagination */

.paging-button-kids {
	border-radius: 50%!important;
	border-color: rgb(242, 108, 44);
	border-width: 2px;
	margin-right: 0.5rem!important;
    margin-left: 0.5rem!important;
	width: 38px;
	height: 38px;
	color: rgb(242, 108, 44);
	text-align: center;
	padding: 0.3rem 0.5rem;
}

.paging-button-kids:hover {
    background-color: #ffe4c5;
    border-color: rgb(238, 97, 31);
}

.paging-button-kids--active {
	background-color: rgb(255, 165, 123);
	color: white;
}
	.paging-button-kids--active:focus {
		background-color: rgb(255, 165, 123);
		color: white;
	}
	.paging-button-kids--active:hover {
		background-color: rgb(253, 176, 141);
		color: white;
	}

.paging-button-kids--disabled {
	border-color: rgb(129, 129, 129)!important;
	color: rgb(129, 129, 129)!important;
}

.next-btn::before {
	position: absolute;
    top: -0.1rem;
    left: 0.8rem;
	font-family: "FontAwesome";
	content: "\f105";
	font-size: calc(1.275rem + .3vw)!important;
}
.prev-btn::before {
	position: absolute;
    top: -0.1rem;
    left: 0.8rem;
	font-family: "FontAwesome";
	content: "\f104";
	font-size: calc(1.275rem + .3vw)!important;
}

/* check circle */

.circle-icon {
	border-radius: 100%;
	width: 20px;
	height: 20px;
}

.circle-icon--left {
	position: absolute;
	right: -25px;
	top: 5px;
}

.circle-icon--right {
	position: absolute;
	left: -25px;
	top: 5px;
}
.circle-icon--red {
	border: 2px solid rgb(207, 18, 18);
}
.circle-icon--orange {
	border: 2px solid rgb(242, 108, 44);
}
.circle-icon--mini {
	background: #fff;
	width: 10px;
	height: 10px;
	border-radius: 100%;
	position: absolute;
	top: -2px;
	right: -5px;
}
.circle-icon--check {
	padding: 0px;
	border-radius: 100px;
	position: absolute;
	top: -3px;
	font-size: 18px;
	right: -3px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
	.circle-icon--left {
		position: absolute;
		right: -25px;
		top: 50%;
		transform: translateY(-80%);
	}

	.circle-icon--right {
		position: absolute;
		left: -25px;
		top: 50%;
		transform: translateY(-80%);
	}
}



@media screen and (max-width: 991px) {
	.circle-icon--left {
		position: relative;
		right: unset;
		top: unset;
		margin: 0 10px;
	}
	.circle-icon--right {
		position: relative;
		left: unset;
		top: unset;
		margin: 0 10px;
	}
	.circle-icon--red {
		border: 0px solid rgb(207, 18, 18);
	}
	.circle-icon--orange {
		border: 0px solid rgb(242, 108, 44);
	}
	.circle-icon--check{
		top: 7px;
	}
}

.zalo-chat-widget{
	bottom: 35px !important;
	right: 24px !important;
}

.payment-detail {
	margin-top: 25px;
	width: 400px;
	margin-left: auto;
	margin-right: auto;
	border: 1px solid #797979;
	border-radius: 15px;
	padding: 20px;
}

.payment-detail h4 {
	font-size: 1.1rem;
}

.currency_vnd::after{
	content: " VND";
}