.gt-text {
    word-break: break-word;
    text-align: justify;
    font-size: 24px;
    width: 100%;
}

.box-text {
    position: relative;
    margin: 0 18px;
}
.sub-text-red {
    color: rgb(207, 18, 18);
    font-weight: bold;
    font-size: 20px;
    padding-left: 8px;
}
.sub-text-blue {
    color: #2e3192;
    font-weight: bold;
    font-size: 20px;
    padding-left: 8px;
}
#gioiThieu4 > div p {
    font-size: 20px;
}
#gioiThieu4 > div p:hover {
    color: #f26c2c;
}
@media screen and (min-width: 1200px) {
    #gioiThieu4-center {
        shape-outside: circle(50%);
    }
    #bl-1 {
        margin-right: -50px;
    }
    #bl-2 {
        margin-right: 10px;
    }
    #bl-3 {
        margin-right: 20px;
    }
    #bl-4 {
        margin-right: 12px;
    }
    #bl-5 {
        margin-right: -35px;
    }
    #br-1 {
        margin-left: -50px;
    }
    #br-2 {
        margin-left: 10px;
    }
    #br-3 {
        margin-left: 26px;
    }
    #br-4 {
        margin-left: 25px;
    }
    #br-5 {
        margin-left: -15px;
    }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
    #gioiThieu4-center {
        shape-outside: circle(50%);
    }
    #bl-1 {
        margin-right: -100px;
    }
    #bl-2 {
        margin-right: 10px;
    }
    #bl-3 {
        margin-right: 20px;
    }
    #bl-4 {
        margin-right: 12px;
    }
    #bl-5 {
        margin-right: -35px;
    }
    #br-1 {
        margin-left: -100px;
    }
    #br-2 {
        margin-left: 10px;
    }
    #br-3 {
        margin-left: 26px;
    }
    #br-4 {
        margin-left: 25px;
    }
    #br-5 {
        margin-left: -15px;
    }
}

@media screen and (max-width: 991px) {
    #gioiThieu4 {
        display: flex;
        flex-direction: column;
    }
    .box-text {
        position: static;
        margin: unset;
    }
    #gioiThieu4-left {
        order: 2;
        text-align: start !important;
    }
    #gioiThieu4-right {
        order: 3;
    }
    #gioiThieu4-center {
        order: 1;
        text-align: center !important;
    }

    #gioiThieu5-img {
        width: 350px;
        overflow: hidden;
    }
    #gioiThieu5-img > img {
        width: 400px !important;
    }
}

@media screen and (max-width: 768px) {
    #gioiThieu5-img {
        display: none;
    }
}
