.complete_container {
	background-image: url("../../../../../../public/img/bg_complete.png");
	height: 400px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.complete_container i {
  font-size: 3rem
}
.complete_container h4:last-of-type {
  font-weight: bold;
}
