.carousel-indicators [data-bs-target] {
    background-color: #f26c2c;
}

.overlay-layout {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
}

.slide-bg-img {
    width: 300px;
    height: 300px;
    margin: 0 auto;
}

#slide1 .slide-bg-img {
    right: -30%;
    top: 5%;
}

#slide1 .slide-bg-img > img {
    width: 330px;
}
#slide1 .slide-caption {
    width: 75%;
    text-shadow: rgb(0 0 0 / 36%) 2px 1px 2px;
}
#slide1 .slide-caption h3,
p {
    line-height: unset !important;
}

#slide2 .slide-bg-img {
    left: -30%;
    top: 5%;
}
#slide2 .slide-bg-img > img {
    width: 450px;
}
#slide2 .slide-caption {
    width: 50%;
    margin-bottom: 80px;
    text-shadow: rgb(0 0 0 / 36%) 2px 1px 2px;
}
#slide2 .slide-caption h2, h3,
p {
    line-height: unset !important;
}

@media screen and (max-width: 480px) {
    #slide1 .slide-bg-img {
        left: 0%;
        top: 12%;
    }
    #slide1 .slide-bg-img > img {
        width: 180px;
    }
    #slide1 .slide-caption {
        width: 100%;
        margin-top: -20px;
    }
    #slide1 .slide-caption img {
        position: absolute;
        top: -90%;
        left: 0%;
        /* width: 80px; */
    }
    #slide1 .slide-caption h3,
    p {
        font-size: 16px;
    }
    #slide1 .slide-caption p a {
        font-size: 16px;
    }

    #slide2 .slide-bg-img {
        left: 0%;
        top: 12%;
    }
    #slide2 .slide-bg-img > img {
        width: 220px;
    }
    #slide2 .slide-caption {
        width: 100%;
        margin-top: -20px;
        margin-bottom: 80px;
    }
    #slide2 .slide-caption h2 {
        font-size: 18px;
    }
    #slide2 .slide-caption h3 {
        font-size: 16px;
    }
}
/* @media screen and (max-width: 375px) {
    #slide1 .slide-caption img {
        position: absolute;
        top: -75%;
        left: 0%;
        width: 80px;
    }
} */
@media screen and (max-width: 375px) {
    #slide1 .slide-bg-img {
        left: 0%;
        top: 12%;
    }
    #slide1 .slide-bg-img > img {
        width: 160px;
    }

    #slide1 .slide-caption img {
        position: absolute;
        top: -75%;
        left: 0%;
        width: 60px;
    }
}
