.about_view_container h5 {
	color: rgb(242, 108, 44);
	font-weight: bold;
}

@media (min-width: 768px) {
	.about_view_container {
		margin-bottom: 2rem;
	}

	.s1-img {
		margin-top: -70%;
	}

	.about_view_container .item_absolute_container {
		position: absolute;
		bottom: -140px;
		left: 0;
	}
	.about_view_container .item_absolute_container div:last-child {
		display: flex;
		align-items: flex-end;
	}
}
