.news-img {
    padding: 0px;
}

.news-review {
    padding-left: 1rem!important;
}

@media screen and (max-width: 991px) {
    .news-img {
        padding: 10px;
    }

    .news-img > img {
        width: 100%;
    }
    .news-review {
        padding-right: 1rem!important;
    }
}