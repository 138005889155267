@font-face {
  font-family: "UTMAvo-Bold";
  src: local("UTMAvo-Bold"),
   url("./fonts/UTMAvo/UTMAvo-Bold.ttf") format("truetype");
  font-weight: bold;
  }
@font-face {
  font-family: "UTMAvo";
  src: local("UTMAvo"),
   url("./fonts/UTMAvo/UTMAvo.ttf") format("truetype");
  font-weight: normal;
  }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  height: 100%!important;
}
#root {
      height: 100%!important;
      display: flex!important;
      flex-direction: column!important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 5px;
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}