.carousel-btn-custom {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    width: 50px;
    border-radius: 50%;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
}
.carousel-btn-custom--next {
    right: -60px;
    height: 50px;
}
.carousel-btn-custom--next:hover {
    opacity: 1;
}
.carousel-btn-custom--prev {
    left: -60px;
    height: 50px;
}
.carousel-btn-custom--prev:hover {
    opacity: 1;
}
.carousel-custom {
    width: 600px;
    margin: auto;
    position: relative;
}
.dt-text {
    color: #3f44ca;
    width: 80%;
    font-size: 20px;
}
@media screen and (max-width: 768px)  {
    .carousel-btn-custom {
        z-index: 999;
    }
    .carousel-custom {
        width: 400px;
    }
}

@media screen and (max-width: 480px)  {
    .carousel-custom {
        width: 250px;
    }
}

