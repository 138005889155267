.price-name,
.price-body-header {
	font-family: "UTMAvo-Bold";
	font-weight: 400;
	color: #0d6efd;
	margin-bottom: 0.5rem;
}

.price-target {
	font-family: "UTMAvo-Bold";
	font-weight: 400;
	color: rgb(255 140 41);
	margin-bottom: 0.5rem;
}

.price-body {
	min-height: 100px;
	margin-bottom: 1rem;
}

.price-tag {
	display: flex;
	flex-wrap: wrap;
}
.price-tag .left {
	flex: 0 0 auto;
	width: 50%;
	text-align: center;
	font-size: 17px;
}

.price-tag .right {
	font-family: "UTMAvo-Bold";
	flex: 0 0 auto;
	width: 50%;
	text-align: left;
	margin: auto;
	font-size: 15px;
	color: #0d6efd;
}

.price-tag .money {
	font-family: "UTMAvo-Bold";
	color: #0d6efd;
}

.price-tag .month {
	font-family: "UTMAvo";
	color: #fd0d35;
}

.price-tag hr {
	height: 2px;
	opacity: 1;
	color: #0d6efd;
}
.price-tag .vr {
	opacity: 1;
	width: 2px;
	color: #0d6efd;
}

.pricing-bg1 {
	background-image: linear-gradient(to bottom, white, rgb(188, 255, 210));
	border: 1px solid rgb(152, 209, 171);
}
.pricing-bg2 {
	background-image: linear-gradient(to bottom, white, rgb(188, 228, 255));
	border: 1px solid rgb(140, 193, 228);
}
.pricing-bg3 {
	background-image: linear-gradient(to bottom, white, rgb(208, 188, 255));
	border: 1px solid rgb(178, 154, 235);
}
.pricing-bg4 {
	background-image: linear-gradient(to bottom, white, rgb(255, 224, 188));
	border: 1px solid rgb(238, 202, 160);
}

.table_container table,
.table_container th,
.table_container td {
	border: 1px solid rgb(255 140 41);
}
.table_container tbody {
	border-top: unset !important;
}
.table_container thead p {
	margin-bottom: 0;
	margin-top: 0.5rem;
	color: rgb(255 140 41);
}
.table_container thead h5,
.table_container thead h6 {
	margin-bottom: 0;
	color: rgb(255 140 41);
	font-weight: bold;
}
.table_container thead h6 {
	margin-top: 0.5rem;
}
.table_container .title_row div {
	width: 25px;
	height: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f5b52b;
	color: white;
	font-size: 15px;
	border-radius: 50%;
}
.table_container .title_row span {
	margin-left: 0.5rem;
	color: #f5b52b !important;
	font-weight: bold;
}
.table_container tbody tr td {
	color: #0d6efd;
}
